import { searchMyCustomerList } from '~/api/messageCenter'

export const useCustomer = () => {
  const customerLink = ref<string>('/messageCenter')
  const getCustomerLink = (type: number) => {
    if (type === 2) {
      // 普通客服直接跳客服列表
      customerLink.value = '/messageCenter?customerType=2'
      return
    }
    searchMyCustomerList({ model: { customerType: type } }).then((res) => {
      customerLink.value = res.length > 0 ? res[0]?.chatUrl ?? '' : ''
    })
  }

  return {
    customerLink,
    getCustomerLink
  }
}
